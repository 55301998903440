/**
 * Company: SPHMedia
 * Description: [GE Web Banner] To entry point to the GE page.
 */
import GELogo from "@assets/ge/GE2025-logo.png";
import ChevronImage from "@assets/video_widget/Chevron.webp";
import Image from "@components/ResponsiveImage/Image";
import { ReactElement } from "react";
import { Link } from "react-router-dom";

/**
 * GEWebBanner
 * @returns The rendered React element
 */
export default function GEWebBanner(): ReactElement {
  return (
    <div className="bg-gray-500 border-gray-2050 border-y w-full text-2.5md lg:text-md font-bold leading-6 lg:leading-9 flex justify-items-start lg:justify-center items-center h-[52px] lg:h-[45px] my-5 px-[15px] py-2.5">
      <div className="pr-2.5">
        <Image
          srcWidth={35}
          srcHeight={35}
          src={GELogo}
          objectFit="contain"
          alt="பொதுத் தேர்தல் 2025"
          className="w-8 lg:w-9"
        />
      </div>
      <div className="hidden lg:block pr-2.5 text-gray-100">
        பொதுத் தேர்தல் 2025
      </div>
      <div className="block lg:hidden pr-2.5 text-red-100">
        <Link reloadDocument to={`/general-election-2025`} target="_blank">
          பொதுத் தேர்தல் 2025
          <img
            alt="toggle-button-chevie"
            height={30}
            width={30}
            className="pl-[5px] inline"
            src={ChevronImage}
          />
        </Link>
      </div>

      <div className="text-red-100 hidden lg:block ">
        <Link reloadDocument to={`/general-election-2025`} target="_blank">
          அண்மைய செய்திகளைப் படிக்க
          <img
            alt="toggle-button-chevie"
            height={30}
            width={30}
            className="pl-[5px] inline"
            src={ChevronImage}
          />
        </Link>
      </div>
    </div>
  );
}
