import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { capitalize } from "@helper/stringTransform";
import type { KeywordMetaDataProps } from "@pages/Keyword/KeywordMetaData";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export function GeMetaData({
  tagName,
  tagAlias,
}: KeywordMetaDataProps): PageMetaDataValues {
  const level2 = tagName.replace("-", " ");
  const capitalizeTagName = capitalize(level2);

  const metaTitle = `${capitalizeTagName}, ${capitalizeTagName} செய்திகள் - ${capitalizeTagName} news | Tamil Murasu`;
  const metaDescription = `${capitalizeTagName} news | Tamil Murasu - Get Latest Singapore, Tamilnadu, India, World & Sports News in Tamil. சிங்கப்பூரின் ஒரே தமிழ் மொழி செய்தித்தாள். சிங்கப்பூர் செய்திகள், இந்தியா செய்திகள், தமிழ்நாடு செய்திகள், விளையாட்டு செய்திகள்.`;

  const metaTags: MetaTagsProps = {
    title: metaTitle,
    description: metaDescription,
    slug: `keywords/${tagAlias}`,
    keywords: [
      "Singapore General Election 2025",
      "GE2025",
      "சிங்கப்பூர் பொதுத் தேர்தல் 2025",
      "சிங்கப்பூர் பொதுத் தேர்தல்",
    ],
  };

  const gaData: GADataProps = {
    chapter1: tagName,
    level2: "keywords",
    title: `${tagName}_Index`,
    contentcat: 1,
    contenttype: undefined,
  };

  return {
    gaData,
    metaTags,
  };
}
