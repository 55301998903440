import { HamburgerMenuIcon } from "@header/HamburgerMenu/HamburgerMenuIcon";
import { HamburgerMenuModal } from "@header/HamburgerMenu/HamburgerMenuModel";
import { HeaderLogo } from "@header/HeaderLogo";
import { HeaderSubscribe } from "@header/HeaderSubscribe";
import { SearchButton } from "@header/Search/SearchButton";
import { SearchInput } from "@header/Search/SearchInput";
import { TopHeader } from "@header/TopHeader/TopHeader";
import cx from "classnames";
import { useState } from "react";

export type HeaderProps = {
  testId?: string;
  sectionName?: string;
};

export function Header({ testId = "header", sectionName }: HeaderProps) {
  const [showMenuIcon, setShowMenuIcon] = useState(true);
  const [expandMenu, setExpandMenu] = useState(false);

  const toggleMobileMenu = () => {
    setShowMenuIcon((prevShowMenuIcon) => !prevShowMenuIcon);
    setExpandMenu((prevExpandMenu) => !prevExpandMenu);
  };

  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div className="xs:sticky lg:relative bg-white-100">
      <TopHeader />
      <div data-testid={`${testId}-headerContainer`} className="z-0 relative">
        <div
          className={cx(
            "lg:relative bg-white-100 xl:max-w-xl xl:px-md lg:max-w-lg lg:p-0 lg:h-21 lg:mb-0 lg:px-sm lg:grid lg:grid-cols-6 md:max-w-xm sm:max-w-xs w-full mx-auto mb-0.5 h-14 px-3.5 pt-3.5",
            sectionName === "maanavar-murasu" && "lg:h-24 lg:mt-2",
          )}
        >
          <HeaderLogo testId={testId} sectionName={sectionName} />

          <div className="m-auto hidden lg:flex h-11 py-4xs">
            <HeaderSubscribe />
            <span className="border-l border-solid border-gray-400 ml-5"></span>
            <SearchButton onClick={toggleExpanded} />
          </div>

          <div
            data-testid={`${testId}-hamburger-menu-container`}
            className="inline-flex float-right lg:hidden"
          >
            <SearchButton onClick={toggleExpanded} />
            <span className="border-l border-solid border-gray-400 inline lg:hidden" />
            <HamburgerMenuIcon
              showMenuIcon={showMenuIcon}
              expandMenu={expandMenu}
              onClick={toggleMobileMenu}
            />
          </div>
        </div>

        <SearchInput
          expanded={expanded}
          className="lg:max-w-xl mx-auto px-sm pb-4 bg-white-100"
        />

        <HamburgerMenuModal
          expandMenu={expandMenu}
          onClick={toggleMobileMenu}
        />
      </div>
    </div>
  );
}
