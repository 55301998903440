import MainBlock from "@container/MainBlock";
import WrapperBlock from "@container/WrapperBlock";
import { AdsImu2 } from "@elements/Advertisement/variant/AdsImu";
import Divider from "@elements/Divider/Divider";
import GEArticles from "@elements/GeneralElection/GEArticles";
import GELiveBlog from "@elements/GeneralElection/GELiveBlog";
import GEMapWidget from "@elements/GeneralElection/GEMapWidget";
import GESTWidget from "@elements/GeneralElection/GESTWidget";
import { type ProcessedArticleData } from "@transformer/useOsResponse";
import { PageAdTargetValue } from "@typings/Ads.d";
import { ReactElement } from "react";

type GeVariant4Props = {
  geGovTechWidget?: string;
  geLiveBlog?: string;
  geRecordedVideo?: string;
  geSTWidget?: string;
  keywordArticles: ProcessedArticleData[];
  tagAlias: string;
  sectionName: string;
  pageAdTargetValue: PageAdTargetValue;
  showBreadcrumbs: boolean;
  showTitle: boolean;
  tagName: string;
};

export default function GEVariant4({
  geGovTechWidget,
  geLiveBlog,
  geRecordedVideo,
  geSTWidget,
  keywordArticles,
  tagAlias,
  sectionName,
  pageAdTargetValue,
  showBreadcrumbs,
  showTitle,
  tagName,
}: GeVariant4Props): ReactElement {
  return (
    <>
      {geSTWidget && <GESTWidget geSTWidgetContent={geSTWidget} />}
      <WrapperBlock className="flex flex-col lg:flex-row justify-between">
        <MainBlock
          sectionName={tagName}
          showBreadcrumbs={showBreadcrumbs}
          showTitle={showTitle}
          className="lg:!pr-0 lg:border-0"
        >
          {geRecordedVideo && (
            <div dangerouslySetInnerHTML={{ __html: geRecordedVideo }} />
          )}
          {geLiveBlog && (
            <GELiveBlog variant="body" geLiveBlogContent={geLiveBlog} />
          )}
        </MainBlock>
        <div className="lg:w-4/12 lg:pr-xxs lg:pl-md">
          <div data-testid="side-block" className="w-full">
            {geGovTechWidget && (
              <GEMapWidget
                geGovTechContent={geGovTechWidget}
                wrapperStyle="w-[300px] h-full"
              />
            )}
            <AdsImu2
              sectionName={sectionName}
              pageAdTargetType={pageAdTargetValue}
            />
            <Divider className="border-b border-gray-2200 my-5" />
            <GEArticles
              variant="sidebar"
              keywordArticles={keywordArticles}
              tagAlias={tagAlias}
              sectionName={sectionName}
              pageAdTargetValue={pageAdTargetValue}
            />
          </div>
        </div>
      </WrapperBlock>
    </>
  );
}
