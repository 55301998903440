import ArticleCard from "@components/Card/ArticleCard";
import { Imu1CompanionAdsInfiniteScroll } from "@elements/Advertisement/variant/AdsCompanion";
import Heading from "@elements/Typography/Heading";
import fetchTMApi from "@helper/fetchTMApi";
import { displayAfter, hideAfter } from "@helper/utils";
import {
  CreateLoadMoreHandler,
  type CreateLoadMoreHandlerProps,
} from "@hooks/useLoadMore/CreateLoadMoreHandler";
import LoadMore from "@hooks/useLoadMore/LoadMore";
import useSetFirstRenderedArticleIds from "@hooks/useLoadMore/SetFirstRenderedArticleIds";
import { ProcessedArticleData } from "@transformer/useOsResponse";
import { PageAdTargetValue } from "@typings/Ads.d";
import { MoreArticlesRequestData } from "@typings/MoreArticlesApi";
import { Fragment, ReactElement, useState } from "react";

type GEArticlesProps = {
  variant: string;
  keywordArticles: ProcessedArticleData[];
  tagAlias: string;
  sectionName: string;
  pageAdTargetValue: PageAdTargetValue;
};

const PAGE_SIZE = 5;
const INITIAL_ARTICLE_COUNT = 5;

export default function GEArticles({
  variant = "main",
  keywordArticles,
  tagAlias,
  sectionName,
  pageAdTargetValue,
}: GEArticlesProps): ReactElement {
  const [hasMoreStories, setHasMoreStories] = useState<boolean>(true);
  const [renderedArticleIds, setRenderedArticleIds] = useState(
    new Set<string>(),
  );
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loadMoreData, setLoadMoreData] = useState<ProcessedArticleData[]>([]);

  const showLoadMore: boolean = keywordArticles.length == INITIAL_ARTICLE_COUNT;
  useSetFirstRenderedArticleIds(keywordArticles, setRenderedArticleIds);

  const handleLoadMore = async () => {
    const lastLoadedArticle =
      loadMoreData.length == 0 ? keywordArticles.at(-1) : loadMoreData.at(-1);
    const requestData: MoreArticlesRequestData = {
      filterArticlesBasedOn: tagAlias,
      sort: lastLoadedArticle?.sort,
    };

    const fetchMoreTagsStoriesData = async (
      requestData: MoreArticlesRequestData,
    ): Promise<ProcessedArticleData[] | []> => {
      const queryParams = new URLSearchParams({
        tagAlias: requestData.filterArticlesBasedOn as string,
        sort: Array.isArray(requestData.sort) ? requestData.sort.join(",") : "",
        size: "5",
      }).toString();
      const response: ProcessedArticleData[] = await fetchTMApi(
        `more-tags-stories?${queryParams}`,
        "GET",
      );

      return response;
    };
    const createLoadMoreProps: CreateLoadMoreHandlerProps = {
      requestData,
      fetchFunction: fetchMoreTagsStoriesData,
      setHasMoreStories,
      setRenderedArticleIds,
      setLoadMoreData,
      setPageNumber,
      loadMoreData,
      renderedArticleIds,
      lastLoadedArticle,
      pageSize: PAGE_SIZE,
      pageNumber,
    };
    const response = await CreateLoadMoreHandler(createLoadMoreProps);
    return response;
  };

  return (
    <>
      <Heading Element="h2" className="text-gray-100 text-md font-bold mt-5">
        GE2025 செய்திகள்
      </Heading>
      {[...keywordArticles, ...loadMoreData].map((section, index) => (
        <Fragment key={section.id}>
          {variant == "main" ? (
            <>
              <ArticleCard
                displayWidth={260}
                displayHeight={192}
                elements={{ hasDate: true }}
                {...section}
                overrideStyle={{
                  cardWrapper:
                    (displayAfter(index + 1, PAGE_SIZE)
                      ? "border-none"
                      : "flex-col sm:flex-row") +
                    (hideAfter(index, PAGE_SIZE)
                      ? " sm:flex-row flex-row-reverse"
                      : ""),
                  titleStyle: hideAfter(index, PAGE_SIZE)
                    ? "font-semibold text-2sm text-gray-100 hover:text-gray-100 sm:text-3md sm:text-red-100 sm:hover:text-red-200"
                    : "text-md sm:text-3md",
                  paragraphStyle: hideAfter(index, PAGE_SIZE)
                    ? "hidden sm:block"
                    : "",
                  imageWrapperStyle: hideAfter(index, PAGE_SIZE)
                    ? "w-[90px] h-[90px] sm:h-auto"
                    : "",
                  imageStyle: hideAfter(index, PAGE_SIZE)
                    ? "h-[90px] sm:h-auto"
                    : "",
                }}
                refMedium={sectionName}
              />
            </>
          ) : (
            <>
              <ArticleCard
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                key={section.id}
                displayWidth={175}
                displayHeight={90}
                elements={{ hasParagraph: false, hasDate: true }}
                {...section}
                overrideStyle={{
                  cardWrapper: "w-full lg:w-full flex-row-reverse pb-5",
                  titleStyle:
                    "font-semibold font-primary text-sm2 text-gray-100 hover:text-gray-100",
                  imageWrapperStyle: "w-40 max-w-44 lg:w-40 h-23 max-sm:w-23",
                  contentWrapper: "gap-xs",
                  titleWrapper: "gap-0",
                  imageStyle: "w-full h-23",
                }}
              />
            </>
          )}
          {displayAfter(index + 1, PAGE_SIZE) && (
            <Imu1CompanionAdsInfiniteScroll
              sectionName={sectionName}
              index={(index + 1) / PAGE_SIZE}
              pageAdTargetType={pageAdTargetValue}
            />
          )}
        </Fragment>
      ))}
      {showLoadMore ? (
        <div className="flex w-full justify-center mb-xl">
          <LoadMore
            rootClassName="my-4 w-full"
            onLoadMore={handleLoadMore}
            hasMore={hasMoreStories}
            loadText="Load More"
            mobileInfiniteScroll={variant == "main" ? false : true}
          />
        </div>
      ) : null}
    </>
  );
}
