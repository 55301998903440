import App from "@app/App";
import AboutUs from "@pages/AboutUs/AboutUs";
import ArticlePage from "@pages/Article/Article";
import TmPicks from "@pages/Article/TmPicks";
import AuthorPage from "@pages/Author/AuthorPage";
import BalarMurasu from "@pages/BalarMurasu/BalarMurasu";
import GePage from "@pages/CustomPages/GePage";
import HomePage from "@pages/Home/Home";
import Horoscope from "@pages/Horoscope/Horoscope";
import KeywordPage from "@pages/Keyword/Keyword";
import Newsletter from "@pages/Newsletter/Newsletter";
import PhotoGalleryPage from "@pages/PhotoGallery/PhotoGallery";
import PhotoGalleryDetailPage from "@pages/PhotoGallery/PhotoGalleryDetailPage";
import PodcastPage from "@pages/Podcast/Podcast";
import QuizPage from "@pages/Quiz/Quiz";
import RecentArticlesPage from "@pages/RecentArticles/RecentArticles";
import { SearchResultPage } from "@pages/Search/SearchResult";
import SectionPage from "@pages/Section/Section";
import StatusHandler from "@pages/StatusHandler/StatusHandler";
import TranslationPage from "@pages/Translation/Translation";
import VideoPage from "@pages/Video/Video";
import VideoDetailPage from "@pages/Video/VideoDetailPage";
import { AuthCallback } from "@sphtech/web2-core/auth";
import { fetchContext, ResponseType } from "@sphtech/web2-core/ssr";
import { RouteObject } from "react-router-dom";

import {
  getClientId,
  getIssuer,
} from "../../.web2/web2-helpers/auth/MySphConfig";

const errorElement = import.meta.env.PROD ? (
  <StatusHandler clientSideError />
) : undefined;

export const reactRouterRoutes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <HomePage />,
        errorElement,
        loader: fetchContext,
      },
      /**
       * Integration guide: https://sph.atlassian.net/wiki/x/V4GqY
       */

      {
        path: "/oauth/callback",
        element: <AuthCallback clientId={getClientId()} issuer={getIssuer()} />,
        errorElement,
      },
      {
        path: "/horoscope/:zodiac",
        element: <Horoscope />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/article/:id",
        element: <ArticlePage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/apple-app-site-association",
        element: <></>,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/.well-known/assetlinks.json",
        element: <></>,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/videos",
        element: <VideoPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/videos/:level1",
        element: <VideoDetailPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/tm-picks/:level1",
        element: <TmPicks />,
        errorElement,
        loader: fetchContext,
      },
      // trigger deployment {
      //   path: "/:section/:second/:slug",
      //   element: <ArticlePage />,
      // errorElement,
      //   loader: fetchContext,
      // },
      {
        path: "/podcasts",
        element: <PodcastPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/podcasts/:level1",
        element: <PodcastPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/recent-articles",
        element: <RecentArticlesPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/balar-murasu",
        element: <BalarMurasu />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/photo-gallery",
        element: <PhotoGalleryPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/photo-gallery/:slug",
        element: <PhotoGalleryDetailPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/general-election-2025",
        element: <GePage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/keywords/:level1",
        element: <KeywordPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/author/:level1",
        element: <AuthorPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/search/site/:searchValue",
        element: <SearchResultPage />,
        loader: fetchContext,
      },
      {
        path: "/:section/:slug",
        element: <ArticlePage />,
        errorElement,
        loader: fetchContext,
      },
      // {
      //   path: "/:level1/:level2",
      //   element: <SectionPage />,
      // errorElement,
      //   loader: fetchContext,
      // },
      {
        path: "/newsletter/sign-up",
        element: <Newsletter />,
        errorElement,
      },
      {
        path: "/tamil-translation-service-singapore",
        element: <TranslationPage />,
        loader: fetchContext,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
        loader: fetchContext,
      },
      {
        path: "/tamil-quiz",
        element: <QuizPage />,
        loader: fetchContext,
      },
      {
        path: "/:level1",
        element: <SectionPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "*",
        element: (
          <StatusHandler
            response={{
              type: ResponseType.CLIENT_ERROR,
              statusCode: 404,
            }}
          />
        ),
        errorElement,
      },
    ],
  },
];

export const routes = reactRouterRoutes.reduce(
  (_routes: string[], currentRoute: RouteObject) => {
    if (currentRoute.path) {
      _routes = [..._routes, currentRoute.path];
    }

    if (currentRoute.children) {
      for (const child of currentRoute.children) {
        if (child.path) {
          _routes = [..._routes, child.path];
        }
      }
    }

    return _routes;
  },
  [],
);
